<!--门店管理-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-button type="primary" block size="small" @click="edit()">新建门店</el-button>
            </div>
        </div>
		
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="name" label="门店名称" show-overflow-tooltip>
				</el-table-column>
                <el-table-column prop="number" label="负责人" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.user_name}} {{scope.row.tel}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="营业时间"  show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>{{scope.row.start_time}} - {{scope.row.end_time}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="开关"  show-overflow-tooltip width="100">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" :active-value="1"  :inactive-value="2" @change="setupSwitch(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="300">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="enterPlatform(scope.row)">进入门店平台</el-button>
                        <el-button type="success" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
			
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {houseListApi,houseDeleteApi,houseSwitchApi,enterHouseApi}  from '@/api/house.js';
    export default {
        data() {
            return {
                list:[], //列表

                title:'添加门店', //新建or编辑的标题

                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数

                dialogFormVisible:false, //新建编辑弹出框状态
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //门店管理列表
            getList: function(){
                houseListApi({
                    curr:this.curr,
                    row:this.row
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },

			//门店开关
            setupSwitch: function(row){
                houseSwitchApi({
                    id:row.id,
                    status:row.status
                }).then(()=>{
                    this.$message({
                        type: 'success',
                        message: '设置成功!'
                    });
                    this.getList();
                })
            },
            
            //跳转门店平台
            enterPlatform: function(param){
                let _this = this;
                enterHouseApi({
                    id:param.id
                })

                setTimeout(function(){
                    _this.$set(param,'is_admin',1)
                    localStorage.setItem('houseInfo', JSON.stringify(param));
                    _this.$router.push({path:'/houseOverview'})
                },200)
            },

            //编辑门店
            edit: function(param = {}){
                //判断添加商品or编辑商品
                if(typeof param.id == 'undefined'){
                    this.title = '添加门店';
                    this.isShow = false;
                }else {
                    this.title = '编辑门店';
                    this.isShow = false;
                }
                //跳转至添加or编辑页面
                this.$router.push({path:'/houseAdd',query:{id:param.id,title:this.title}});
            },

            //删除门店
            deletes: function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    houseDeleteApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{})
            },

            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            }
        }
    }
</script>